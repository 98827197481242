import * as React from "react"
import { Link, graphql } from "gatsby"

import { FaRegArrowAltCircleLeft } from "@react-icons/all-files/fa/FaRegArrowAltCircleLeft"
import { FaRegArrowAltCircleRight } from "@react-icons/all-files/fa/FaRegArrowAltCircleRight"

import Bio from "../../components/bio"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article className="" itemScope itemType="http://schema.org/Article">
        <header className="font-nunito pt-3 mb-4 flex flex-row justify-center">
          <div className="flex flex-col p-6 w-full max-w--[1200px] items-center">
            <h1
              itemProp="headline"
              className="text-4xl md:text-5xl text-stone-700 dark:text-stone-800 text-center"
            >
              {post.frontmatter.title}
            </h1>
            <p className="text-center">{post.frontmatter.date}</p>
          </div>
        </header>
        <section className="flex flex-col items-center">
          <div
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
            className="p-6 article-body-text font-nunito text-lg w-full md:w-5/6 max-w--[1200px]"
          ></div>
        </section>
        <hr />
        <footer className="pl-14">
          <Bio />
        </footer>
      </article>
      <nav className="blog-post-nav">
        <ul className="list-none flex flex-row justify-center">
          <li>
            {previous && (
              <Link to={"/blog" + previous.fields.slug} rel="prev">
                <div className="flex flex-row">
                  <FaRegArrowAltCircleLeft className="pt-1 text-2xl" />
                  <p className="font-nunito text-center text-lg">
                    <span className="font-bold">Previous Article:</span>{" "}
                    {previous.frontmatter.title}
                  </p>
                </div>
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={"/blog" + next.fields.slug} rel="next">
                <div className="flex flex-row">
                  <FaRegArrowAltCircleRight className="pt-1 text-2xl" />
                  <p className="font-nunito text-center text-lg">
                    <span className="font-bold">Next Article:</span>{" "}
                    {next.frontmatter.title}
                  </p>
                </div>
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
